import { createSlice } from "@reduxjs/toolkit";
/* eslint-disable no-param-reassign */
const lobbyGames = createSlice({
  name: "lobbyGamesResp",
  initialState: {
    value: [],
    gamesMap: {},
    cashoutValue: [],
  },
  reducers: {
    getLobbyGames: (state, action) => {
      const games = action.payload;
      // const sortedGames = games?.sort((a, b) => {
      //   return a.name.localeCompare(b.name);
      // });
      // console.log('sortedGames', sortedGames);
      state.value = games; // Update the value in the store
      state.gamesMap = games.reduce((acc, curr) => {
        acc[curr.gameId] = curr;
        return acc;
      }, {});
    },
    getLobbyCashouts: (state, action) => {
      const games = action.payload;
      state.cashoutValue = games; // Update the value in the store
      // state.gamesMap = games.reduce((acc, curr) => {
      //   acc[curr.gameId] = curr;
      //   return acc;
      // }, {});
    },
  },
});
export const { getLobbyGames, getLobbyCashouts } = lobbyGames.actions;
export default lobbyGames.reducer;
