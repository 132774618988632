import { createSlice } from "@reduxjs/toolkit";
import storage from "../../services/storage";

const user = storage.get("user");
const initialState = {
  user: user || {},
  state: {},
  jumio: {},
  states: [],
};

const eidtUserSlice = createSlice({
  name: "editUser",
  initialState,
  reducers: {
    setEditUser: (state, action) => {
      const user = action.payload;
      state.user = user;
      storage.store("user", user);
    },
    setUserState: (state, action) => {
      state.state = action.payload;
    },
    setUserJumio: (state, action) => {
      state.jumio = action.payload;
    },
    setHeatMapStates: (state, action) => {
      const data = action.payload;
      state.states = data;
      storage.store("statesData", data);
    }
  },
});

export const { setEditUser, setUserState, setUserJumio, setHeatMapStates } =
  eidtUserSlice.actions;
export default eidtUserSlice.reducer;
