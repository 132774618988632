// Confetti.js
import React, { useEffect, useState } from "react";
import "../../assets/Confetti.css"; // Import CSS for styling
import Lottie from "lottie-react";
import animationData from "../../assets/images/coins/lottieAnimationData.json";
import gold0 from "../../assets/images/coins/Gold0-min.png";
import gold1 from "../../assets/images/coins/Gold1-min.png";
import gold2 from "../../assets/images/coins/Gold2-min.png";
import gold3 from "../../assets/images/coins/Gold3-min.png";
import bronze0 from "../../assets/images/coins/bronzess-min.png";
import bronze1 from "../../assets/images/coins/bronzess2-min.png";
import bronze2 from "../../assets/images/coins/bronzess3-min.png";
import bronze3 from "../../assets/images/coins/bronzess4-min.png";
import silver0 from "../../assets/images/coins/Silver0-min.PNG";
import silver1 from "../../assets/images/coins/Silver1-min.PNG";
import silver2 from "../../assets/images/coins/Silver2-min.PNG";
import silver3 from "../../assets/images/coins/Silver3-min.PNG";
import { useDispatch } from "react-redux";
import { jackpotActions } from "../../store/ui/jackpot";
// Single image Confetti
// const Confetti = ({ amount, type }) => {
//   const [confettiCount, setConfettiCount] = useState(90);
//   const [showLottie, setShowLottie] = useState(true);

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setShowLottie(false);
//     }, 5000);

//     return () => clearTimeout(timer);
//   }, []);
//   const confettiArray = Array.from({ length: confettiCount }, (_, i) => i);

//   useEffect(() => {
//     const handleResize = () => {
//       if (window.innerWidth <= 520) {
//         setConfettiCount(25);
//       } else if (window.innerWidth <= 768) {
//         setConfettiCount(40);
//       } else if (window.innerWidth <= 1024) {
//         setConfettiCount(60);
//       } else {
//         setConfettiCount(80);
//       }
//     };

//     handleResize();
//     window.addEventListener("resize", handleResize);

//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   return (
//     <>
//       <div className="confetti-container ">
//         {confettiArray.map((_, index) => {
//           const rotateX = Math.random() * 360;
//           const rotateY = Math.random() * 360;
//           const rotateZ = Math.random() * 360;
//           const rotation = Math.random() * 360;
//           return (
//             <div
//               key={index}
//               className={`confetti ${type === "MAIN" ? "confetti-gold" : type === "500" ? "confetti-silver" : "confetti-bronze"}`}
//               style={{
//                 left: `${Math.random() * 100}%`,
//                 animationDuration: `${Math.random() * 3 + 2}s`,
//                 animationDelay: `${Math.random() * 3}s`,
//                 transform: `rotateX(${rotateX}deg) rotateY(${rotateY}deg) rotateZ(${rotateZ}deg)`,
//                 backgroundSize: "contain",
//                 backgroundRepeat: "no-repeat",
//               }}
//             ></div>
//           );
//         })}
//       </div>{" "}

//       {showLottie && (
//         <div className="confetti-lottie-container">
//           <Lottie
//             animationData={animationData}
//             loop={true}
//             style={{ width: 700, height: 700 }}
//           />
//         </div>
//       )}
//       {!showLottie && (
//         <div className="text-container-popup pulsing-popup">
//           <h1 className="f16 yellow_text">Congratulations!</h1>
//           <p>
//             You have been awarded a staggering{" "}
//             <span className="yellow_bold yellow_text">{amount?.toFixed(0)}</span> Coins in our recent
//             jackpot drawing! Your luck has truly paid off, and we can't wait for you to enjoy your
//             exciting winnings!
//           </p>
//         </div>
//       )}

//     </>
//   );
// };

// Multi Image Confetti
// const Confetti = ({ amount, type }) => {
//   const [confettiCount, setConfettiCount] = useState(90);
//   const confettiArray = Array.from({ length: confettiCount }, (_, i) => i);
//   const [showLottie, setShowLottie] = useState(true);

//   const imagesgold = [gold0, gold1, gold2, gold3];
//   const imagesbronze = [bronze0, bronze1, bronze2, bronze3];
//   const imagessilver = [silver0, silver1, silver2, silver3];
//   useEffect(() => {
//     const handleResize = () => {
//       if (window.innerWidth <= 520) {
//         setConfettiCount(25);
//       } else if (window.innerWidth <= 768) {
//         setConfettiCount(40);
//       } else if (window.innerWidth <= 1024) {
//         setConfettiCount(60);
//       } else {
//         setConfettiCount(80);
//       }
//     };

//     handleResize(); // Set initial value based on current window size
//     window.addEventListener("resize", handleResize);

//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setShowLottie(false);
//     }, 5000);

//     return () => clearTimeout(timer);
//   }, []);

//   return (
//     <div className="confetti-container">
//       {confettiArray.map((_, index) => {
//         const rotation = Math.random() * 360;
//         const image = type === "MAIN" ? imagesgold[Math.floor(Math.random() * imagesgold.length)] : type === "500" ? imagessilver[Math.floor(Math.random() * imagessilver.length)] : imagesbronze[Math.floor(Math.random() * imagesbronze.length)]; // Use the appropriate images

//         return (
//           <>
//             <div
//               key={index}
//               className="confetti"
//               style={{
//                 left: `${Math.random() * 100}%`,
//                 animationDuration: `${Math.random() * 3 + 2}s`,
//                 animationDelay: `${Math.random() * 3}s`,
//                 transform: `rotate(${rotation}deg)`,
//                 backgroundImage: `url(${image})`,
//               }}
//             ></div>
//             {showLottie && (
//               <div className="confetti-lottie-container">
//                 <Lottie
//                   animationData={animationData}
//                   loop={true}
//                   style={{ width: 700, height: 700 }}
//                 />
//               </div>
//             )}
//             {!showLottie && (
//               <div className="text-container-popup pulsing-popup">
//                 <h1 className="f16 yellow_text">Congratulations!</h1>
//                 <p>
//                   You have been awarded a staggering{" "}
//                   <span className="yellow_bold yellow_text">{amount?.toFixed(0)}</span> Coins in our
//                   recent jackpot drawing! Your luck has truly paid off, and we can't wait for you to
//                   enjoy your exciting winnings!
//                 </p>
//               </div>
//             )}
//           </>
//         );
//       })}
//     </div>
//   );
// };

// const Confetti = ({ amount, type }) => {
//   const [confettiCount, setConfettiCount] = useState(90);
//   const confettiArray = Array.from({ length: confettiCount }, (_, i) => i);
//   const [showLottie, setShowLottie] = useState(true);
//   const [showMsg, setShowMsg] = useState(false);

//   // Arrays of image paths
//   const imagesgold = [gold0, gold1, gold2];
//   const imagesbronze = [bronze0, bronze1, bronze2];
//   const imagessilver = [silver0, silver1, silver3];

//   const handleCloseMsg = () => {
//     console.log("handleClose");
//     // setShowMsg(false);
//     // setShowLottie(true);
//   };

//   useEffect(() => {
//     const handleResize = () => {
//       if (window.innerWidth <= 520) {
//         setConfettiCount(25);
//       } else if (window.innerWidth <= 768) {
//         setConfettiCount(40);
//       } else if (window.innerWidth <= 1024) {
//         setConfettiCount(60);
//       } else {
//         setConfettiCount(80);
//       }
//     };

//     handleResize(); // Set initial value based on current window size
//     window.addEventListener("resize", handleResize);

//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setShowLottie(false);
//       setShowMsg(true);
//     }, 3000);

//     return () => clearTimeout(timer);
//   }, []);

//   return (
//     <div className="confetti-container">
//       {confettiArray.map((_, index) => {
//         const rotationX = Math.random() * 360;
//         const rotationY = Math.random() * 360;
//         const rotationZ = Math.random() * 360;

//         // Select an image based on the type
//         const image =
//           type === "MAIN"
//             ? imagesgold[Math.floor(Math.random() * imagesgold.length)]
//             : type === "500"
//               ? imagessilver[Math.floor(Math.random() * imagessilver.length)]
//               : imagesbronze[Math.floor(Math.random() * imagesbronze.length)];

//         return (
//           <div
//             key={index}
//             className="confetti"
//             style={{
//               left: `${Math.random() * 100}%`,
//               animationDuration: `${Math.random() * 3 + 2}s`,
//               animationDelay: `${Math.random() * 3}s`,
//               transform: `rotateZ(${rotationX}deg)  `,
//               backgroundImage: `url(${image})`,
//               backgroundSize: "contain",
//               backgroundRepeat: "no-repeat",
//               width: "100px", // Adjust size as needed
//               height: "100px", // Adjust size as needed
//               // boxShadow: '0 4px 6px rgba(255, 215, 0, 0.7)', // Golden shadow
//             }}
//           ></div>
//         );
//       })}

//       {showLottie && (
//         <div className="confetti-lottie-container">
//           <Lottie animationData={animationData} loop={true} style={{ width: 700, height: 700 }} />
//         </div>
//       )}
//       {showMsg && (
//         <div className="text-container-popup pulsing-popup">
//           <h1 className="f16 yellow_text">Congratulations!</h1>
//           <button onClick={handleCloseMsg}>Close</button>
//           <p>
//             You have been awarded a staggering{" "}
//             <span className="yellow_bold yellow_text">{amount?.toFixed(0)}</span> Coins in our
//             recent jackpot drawing! Your luck has truly paid off, and we can't wait for you to enjoy
//             your exciting winnings!
//           </p>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Confetti;
const Confetti = ({ amount, type }) => {
  const [confettiCount, setConfettiCount] = useState(90);
  const confettiArray = Array.from({ length: confettiCount }, (_, i) => i);
  const [showLottie, setShowLottie] = useState(true);
  const [showMsg, setShowMsg] = useState(false);
  const dispatch = useDispatch();

  // Arrays of image paths
  const imagesgold = [gold0, gold1, gold2];
  const imagesbronze = [bronze0, bronze1, bronze2];
  const imagessilver = [silver0, silver1, silver3];

  const handleCloseMsg = () => {
    setShowMsg(false);
    dispatch(jackpotActions.hideConfetti());
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 520) {
        setConfettiCount(25);
      } else if (window.innerWidth <= 768) {
        setConfettiCount(40);
      } else if (window.innerWidth <= 1024) {
        setConfettiCount(60);
      } else {
        setConfettiCount(80);
      }
    };

    handleResize(); // Set initial value based on current window size
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLottie(false);  // Hide Lottie animation after 3 seconds
      setShowMsg(true);  // Show the congratulation message
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="confetti-container">
      {confettiArray.map((_, index) => {
        const rotationX = Math.random() * 360;
        const rotationY = Math.random() * 360;
        const rotationZ = Math.random() * 360;

        // Select an image based on the type
        const image =
          type === "MAIN"
            ? imagesgold[Math.floor(Math.random() * imagesgold.length)]
            : type === "500"
              ? imagessilver[Math.floor(Math.random() * imagessilver.length)]
              : imagesbronze[Math.floor(Math.random() * imagesbronze.length)];

        return (
          <div
            key={index}
            className="confetti"
            style={{
              left: `${Math.random() * 100}%`,
              animationDuration: `${Math.random() * 3 + 2}s`,
              animationDelay: `${Math.random() * 3}s`,
              transform: `rotateZ(${rotationX}deg)`,
              backgroundImage: `url(${image})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              width: "100px",
              height: "100px",
            }}
          ></div>
        );
      })}

      {showLottie && (
        <div className="confetti-lottie-container">
          <Lottie animationData={animationData} loop={true} style={{ width: 700, height: 700 }} />
        </div>
      )}
      {showMsg && (
        <div className="text-container-popup pulsing-popup">
          <h1 className="f16 yellow_text">Congratulations!</h1>
          <button className="close-popup-btn" onClick={handleCloseMsg}>X</button>  {/* Check this onClick */}
          <p>
            You have been awarded a staggering{" "}
            <span className="yellow_bold yellow_text">{amount?.toFixed(0)}</span> Coins in our
            recent jackpot drawing! Your luck has truly paid off, and we can't wait for you to enjoy
            your exciting winnings!
          </p>
        </div>
      )}
    </div>
  );
};

export default Confetti;
